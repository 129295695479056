import React from 'react';
import '../styles/main.scss';

class Typo extends React.Component {
    render() {
        const { children, variant, color, align } = this.props;

        let classNames = 'typo__' + variant;
        if (color) {
            classNames = classNames + ' typo--' + color;
        }
        if (align) {
            classNames = classNames + ' typo--align-' + align;
        }
        if (color) {
            classNames = classNames + ' typo--color-' + color;
        }

        return <div className={classNames}>{children}</div>;
    }
}

export default Typo;
