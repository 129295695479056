import React from 'react';
import EditorBlock from '../../../editor-base/EditorBlock';

/**
 * Render editing item
 * This renders the input fields for the item that is currently being edited.
 * This also takes into account subsets, if available.
 */
const VisualStackEditorEditInterface = ({ editingItem, subsets, dataModel, language }) => {
    if (!editingItem || !editingItem.interfaceSetup) {
        return <React.Fragment />;
    }

    return editingItem.interfaceSetup.map((item, index) => {
        // Editor block data with subsets
        let editorBlockData;
        if (subsets) {
            editorBlockData = {
                ...item,
                blockModelBase: dataModel + '.' + editingItem.uuid + '.subsetData',
                blockModel: dataModel + '.' + editingItem.uuid + '.subsetData.[[subset]]',
                sourceDataBlockModel: dataModel + '.' + editingItem.uuid + '.sourceData',
                subsets: {
                    ...subsets,
                    blockModel: dataModel + '.' + editingItem.uuid + '.subsetData.[[subset]]',
                    model: dataModel + '.' + editingItem.uuid + '.subsetActive'
                }
            };
        }
        // No subsets, use the base object
        else {
            editorBlockData = {
                ...item,
                blockModel: dataModel + '.' + editingItem.uuid,
                sourceDataBlockModel: dataModel + '.' + editingItem.uuid + '.sourceData'
            };
        }

        return <EditorBlock compact={true} key={'edit-' + editingItem.uuid + '-' + index} data={editorBlockData} language={language} />;
    });
};

export default VisualStackEditorEditInterface;
