import React from 'react';
import { withRouter } from 'react-router-dom';
import { isEqual } from 'lodash';
import Loader from 'components/ui-components/Loader/components/loader';
import Translation from 'components/data/Translation';
import EmptyState from 'components/ui-components-cape/EmptyState';
import Illustration from 'components/ui-components-cape/Illustration';
import GenericFilter from 'components/ui-base/GenericFilter';
import CreativeManagementHelpers from 'components/data/CreativeManagementHelpers';
import ComponentStore, { withComponentStore } from 'components/data/ComponentStore';
import ViewSwitch from 'components/ui-components/ViewSwitch';
import ViewState from 'components/data/ViewState';
import TemplateDialogCreativesResults from './template-dialog-creative-results';

import '../styles/template-dialog-creatives.scss';

class TemplateDialogCreatives extends React.Component {
    constructor(props) {
        super(props);
        const { templateFormats } = props;
        this.setFilters({});
        this.setSearch('');
        CreativeManagementHelpers.loadCreatives(true, templateFormats);
        this.setFilters({});
        const view = ViewState.get('creativeManagement', 'templateDialogCreativesView')
            ? ViewState.get('creativeManagement', 'templateDialogCreativesView')
            : 'grid';

        this.state = {
            view: view
        };
    }

    componentDidUpdate(prevProps) {
        const { filters, searchTerm, templateFormats } = this.props;

        if (!isEqual(prevProps.filters, filters) || prevProps.searchTerm !== searchTerm) {
            ComponentStore.setModel('CreativeManagement', 'creativesLoaded', false);
            CreativeManagementHelpers.loadCreatives(true, templateFormats);
        }
    }

    setFilters = (filters) => {
        const { templateTypes } = this.props;
        ComponentStore.setModel('CreativeManagement', 'filters', { ...filters, templateTypes });
    };

    setSearch = (searchTerm) => {
        ComponentStore.setModel('CreativeManagement', 'searchTerm', searchTerm);
    };

    render() {
        const { creativesList, hasMore, onSelectItem, creativesLoaded, filters, filterSetup, templateTypes, templateLookup } = this.props;
        const { view } = this.state;
        const relevantFilterSetup = filterSetup.filter((f) => f.options && f.options.length > 1);

        return (
            <div className="template-dialog-creatives">
                <div className="template-dialog-creatives__top-bar">
                    <GenericFilter
                        showHorizontal={1}
                        maxOptionsShown={3}
                        inDialog
                        hideChips
                        filters={filters}
                        searchField
                        forceSearchTerm={filters ? filters.query : ''}
                        searchPlaceholder={Translation.get('labels.searchCreatives', 'creative-management')}
                        filterSetup={relevantFilterSetup}
                        ignoreInFiltersCount={['templateTypes']}
                        onSearch={this.setSearch}
                        onMutation={this.setFilters}
                        autoCompleteOptionsLength={5}
                        className="template-dialog-creatives__generic-filter"
                    />
                    <div className="template-dialog-creatives__view-switch">
                        <ViewSwitch
                            onSetDisplayType={(view) => {
                                ViewState.set('creativeManagement', 'templateDialogCreativesView', view);
                                this.setState({ view: view });
                            }}
                            displayType={view}
                        />
                    </div>
                </div>
                <Loader isLoading={!creativesLoaded}>
                    <div className="template-dialog-creatives__list">
                        {creativesList.length > 0 ? (
                            <TemplateDialogCreativesResults
                                results={creativesList}
                                onSelectItem={onSelectItem}
                                templateLookup={templateLookup}
                                hasMore={hasMore}
                                filters={filters}
                                filterSetup={filterSetup}
                                templateTypes={templateTypes}
                                view={view}
                            />
                        ) : (
                            <EmptyState
                                className="template-dialog__placeholder"
                                primaryText={Translation.get('labels.noCreatives', 'creative-management')}
                                secondaryText={Translation.get('labels.readyTo', 'creative-management')}
                                primaryButton={{
                                    label: Translation.get('labels.addCreative', 'creative-management'),
                                    onClick: () => this.props.history.push('/creative-management')
                                }}
                                illustration={<Illustration />}
                            />
                        )}
                    </div>
                </Loader>
            </div>
        );
    }
}

export default withRouter(withComponentStore(TemplateDialogCreatives, 'CreativeManagement'));
