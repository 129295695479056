import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import './../styles/template-dialog-compact-block.scss';

interface Props {
    title?: string;
    icon?: string;
    description?: string;
    onSelectItem: () => void;
}

const TemplateDialogCompactBlock = ({ title, description, onSelectItem, icon }: Props) => {
    return (
        <div className="template-dialog__compact-block" onClick={() => onSelectItem()}>
            <div className="template-dialog__compact-block__container">
                <div className="template-dialog__compact-block__container__icon">
                    <Icon>{icon ? icon : 'bolt'}</Icon>
                </div>
                <div className="template-dialog__compact-block__container__text">
                    {title && <h4>{title}</h4>}
                    {description && <p>{description}</p>}
                </div>
            </div>
        </div>
    );
};

export default TemplateDialogCompactBlock;
