import React, { useEffect } from 'react';
import { TemplateType } from 'types/templates.type';
import CreativeManagementHelpers from 'components/data/CreativeManagementHelpers';
import Setup from 'components/data/Setup';
import { TemplateDataResponse } from 'components/template-designer/services/template-designer.responses';
import Translation from 'components/data/Translation';
import AssetGalleryDialog from 'components/assets/AssetGalleryDialog';
import AssetGalleryDialogSelectorType from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogSelectorType';
import AssetGalleryDialogProps from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogProps';

import '../styles/main.scss';

const DEFAULT_SELECTOR_KEYS: AssetGalleryDialogSelectorType[] = ['template', 'creative'];

interface Props {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    onSelectItem: (item: TemplateDataResponse, libraryTemplate?: boolean) => void;
    orderedTemplates: TemplateDataResponse[];
    libraryTemplates: TemplateDataResponse[];
    options: TemplateDataResponse[];
    categories?: string[];
    templateFormats?: string[];
    itemIncludes?: TemplateType[];
    templateTypes?: TemplateType[];
    compact?: boolean;
    hideCreatives: boolean;
    showLibraryTemplates?: boolean;
    viewType?: string;
    assetGalleryDialogProps?: AssetGalleryDialogProps;
    collectionQuery?: string;
}

interface PreFilters {
    templateTypes?: TemplateType[];
    templateIdentifiers?: string[];
}

/**
 * Template dialog
 * Displays an overview of all templates with icons or visuals.
 */
const TemplateDialog = ({
    isOpen,
    onClose,
    title = Translation.get('formflow.JSON.addItem', 'common'),
    onSelectItem,
    orderedTemplates,
    options,
    categories,
    templateFormats,
    templateTypes,
    compact = false,
    hideCreatives,
    viewType = '',
    libraryTemplates,
    showLibraryTemplates,
    itemIncludes,
    assetGalleryDialogProps
}: Props) => {
    const showCreatives = Setup.hasModule('creativeManagement');
    const defaultSelectorsProps = {
        templateProps: {
            compact: compact,
            libraryTemplates: libraryTemplates,
            showLibraryTemplates: showLibraryTemplates,
            orderedTemplates: orderedTemplates,
            options: options,
            categories: categories,
            viewType: viewType,
            onSelectItem: onSelectItem
        },
        creativeProps: {
            templateTypes: templateTypes,
            itemIncludes: itemIncludes,
            templateFormats: templateFormats,
            onSelectItem: onSelectItem
        }
    }; // Props for the template an creatives selectors.

    /**
     * if showCreatives is true, initialize the creative management filters, set the template identifiers based on props
     */
    useEffect(() => {
        if (!showCreatives) return;

        const preFilter: PreFilters = { templateTypes, templateIdentifiers: [] };
        if (itemIncludes) preFilter.templateIdentifiers = itemIncludes;
        //Template identifiers are not relevant for social channel items they don't belong to a template
        if (templateTypes && templateTypes.length === 1 && templateTypes[0] === 'socialChannelItem') preFilter.templateIdentifiers = [];

        CreativeManagementHelpers.init(preFilter);

        return () => {
            if (showCreatives) CreativeManagementHelpers.setFilters();
        };
    }, [showCreatives]);

    /**
     * Filter the selector keys based on the hideCreatives and showCreatives props
     * @returns If selectors are provided in the assetGalleryDialogProps, return them, otherwise return the default selectors.
     */
    const filterSelectorKeys = () => {
        const selectors = assetGalleryDialogProps?.selectors;
        if (selectors) return selectors; // If selectors are provided in the assetGalleryDialogProps, return them.

        return DEFAULT_SELECTOR_KEYS.filter((selector) => {
            if (selector === 'creative') {
                return showCreatives && !hideCreatives;
            }

            return true;
        }, []); // Otherwise return the default selectors.
    };

    /** If selectorsProps are provided in the assetGalleryDialogProps, return them, otherwise return the default selectorsProps. */
    const getSelectorProps = () => {
        const selectorsProps = assetGalleryDialogProps?.selectorsProps;
        if (selectorsProps) return selectorsProps; // If selectorsProps are provided in the assetGalleryDialogProps, return them.

        return defaultSelectorsProps; // Otherwise return the default selectorsProps.
    };

    return (
        isOpen && (
            <AssetGalleryDialog
                {...assetGalleryDialogProps}
                title={title}
                onMutation={assetGalleryDialogProps?.onMutation ?? (() => null)}
                open={isOpen}
                fullWidth
                fixedHeightPaperScrollPaper
                onClose={onClose}
                selectors={filterSelectorKeys()}
                selectorsProps={getSelectorProps()}
            />
        )
    );
};

export default TemplateDialog;
