import React from 'react';
import classNames from 'classnames';
import LanguageSelector from 'components/editor-base/LanguageSelector';
import VisualStackEditorAbTestTabbar from './ab-test-tabbar';

// #if process.env.customer === 'klm' || process.env.customer === 'afkl' || process.env.customer === 'airfrance'
// import { KLMOriginSwitch } from '../../../customers/KLM/KLMOriginSwitch';
// #endif

import '../styles/topbar.scss';

/**
 * Display the VisualStackEditor topbar
 */
const VisualStackEditorTopbar = ({ abTesting, abTestGroupsShown, value, originSelector, className, onSetAbTestGroupsShown }) => {
    return (
        <div className={classNames('visual-stack-editor-topbar', className)}>
            <div className="visual-stack-editor-topbar__left">
                {abTesting && value.length > 0 && (
                    <VisualStackEditorAbTestTabbar
                        className="visual-stack-editor-topbar__ab-testing"
                        abTesting={abTesting}
                        abTestGroupsShown={abTestGroupsShown}
                        onSetAbTestGroupsShown={onSetAbTestGroupsShown}
                    />
                )}
            </div>
            <div className="visual-stack-editor-topbar__right">
                <div className="visual-stack-editor-topbar__language-container">
                    <LanguageSelector extended={true} />
                </div>
                {(process.env.customer === 'klm' || process.env.customer === 'afkl' || process.env.customer === 'airfrance') && originSelector && (
                    <div className="visual-stack-editor-topbar__origin-selector">
                        <KLMOriginSwitch />
                    </div>
                )}
            </div>
        </div>
    );
};

export default VisualStackEditorTopbar;
