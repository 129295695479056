import React, { useEffect, useMemo, useState } from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import classNames from 'classnames';
import Translation from 'components/data/Translation';
import TemplateHelpers from 'components/data/TemplateHelpers';
import SearchField from 'components/ui-components/SearchField';
import ViewSwitch from 'components/ui-components/ViewSwitch';
import ViewState from 'components/data/ViewState';
import Tabbar from 'components/ui-base/Tabbar';
import { TemplateDataResponse } from 'components/template-designer/services/template-designer.responses';
import TemplateDialogTemplateResults from './template-dialog-template-results';

import '../styles/template-dialog-templates.scss';

export interface TemplateDialogTemplatesProps {
    options: TemplateDataResponse[];
    orderedTemplates: TemplateDataResponse[];
    viewType: string;
    showLibraryTemplates?: boolean;
    libraryTemplates: TemplateDataResponse[];
    onSelectItem: (item: TemplateDataResponse, libraryTemplate?: boolean) => void;
    categories?: string[];
    compact?: boolean;
}

enum TabTypes {
    predefined = 'predefined',
    capeLibrary = 'cape-library'
}

const TemplateDialogTemplates = ({
    options,
    orderedTemplates,
    viewType,
    showLibraryTemplates,
    libraryTemplates,
    onSelectItem,
    categories,
    compact
}: TemplateDialogTemplatesProps) => {
    const [selectedTab, setSelectedTab] = useState<TabTypes>(TabTypes.predefined);
    const [searchCategory, setSearchCategory] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [view, setView] = useState<'grid' | 'list'>('grid');

    // get view from viewstate or use default view
    // if viewType is emailblock use list view as default
    useEffect(() => {
        const defaultView = viewType === 'emailBlock' ? 'list' : 'grid';
        const viewStateValue = ViewState.get('creativeManagement', 'templateDialogTemplatesView' + viewType);

        setView(viewStateValue ? viewStateValue : defaultView);
    }, [viewType]);

    // filter templates based on search term and category
    const { filteredOptions, filteredStaticOptions } = useMemo(() => {
        const templates = selectedTab === 'predefined' ? options : libraryTemplates;

        const newOptions = TemplateHelpers.filter(templates, orderedTemplates, searchCategory, searchTerm);
        const filteredOptions = newOptions.filter((template) => !template.type.includes('staticAsset'));
        const filteredStaticOptions = newOptions.filter((template) => template.type.includes('staticAsset'));

        return { filteredOptions, filteredStaticOptions };
    }, [searchTerm, searchCategory, selectedTab]);

    return (
        <React.Fragment>
            <Grid container className="template-dialog-templates__container">
                {showLibraryTemplates && libraryTemplates.length > 0 && (
                    <Tabbar
                        tabs={[
                            {
                                title: Translation.get('templatesDialog.templates.tabs.templates', 'template-designer'),
                                key: TabTypes.predefined,
                                active: selectedTab === TabTypes.predefined
                            },
                            {
                                title: Translation.get('templatesDialog.templates.tabs.capeLibrary', 'template-designer'),
                                key: TabTypes.capeLibrary,
                                active: selectedTab === TabTypes.capeLibrary
                            }
                        ]}
                        onClick={(tab) => setSelectedTab((tab.key as TabTypes | undefined) || TabTypes.predefined)}
                    />
                )}
                {categories && categories.length > 1 && (
                    <Grid item xs={12} className="template-dialog-templates__categories">
                        <ButtonBase
                            className={classNames('template-dialog-templates__category', {
                                'template-dialog-templates__category--selected': searchCategory === ''
                            })}
                            onClick={() => setSearchCategory('')}>
                            All
                        </ButtonBase>
                        {categories.map(
                            (category) =>
                                category &&
                                category.length > 0 && (
                                    <ButtonBase
                                        key={category}
                                        className={classNames('template-dialog-templates__category', {
                                            'template-dialog-templates__category--selected': searchCategory === category
                                        })}
                                        onClick={() => setSearchCategory(category)}>
                                        {category}
                                    </ButtonBase>
                                )
                        )}
                    </Grid>
                )}
                <Grid item xs={12} className="template-dialog-templates__search">
                    <SearchField onChange={(_, value) => setSearchTerm(value)} />
                    <div>
                        <ViewSwitch
                            onSetDisplayType={(view) => {
                                ViewState.set('creativeManagement', 'templateDialogTemplatesView' + viewType, view);
                                setView(view);
                            }}
                            displayType={view}
                        />
                    </div>
                </Grid>
            </Grid>
            <div className="template-dialog-templates__results">
                <TemplateDialogTemplateResults
                    results={filteredStaticOptions}
                    onSelectItem={onSelectItem}
                    showResultsTitles={!!filteredStaticOptions.length}
                    title={Translation.get('templatesDialog.templates.static', 'template-designer')}
                    view={view}
                    compact={false}
                    selectedTab={selectedTab}
                />
                <TemplateDialogTemplateResults
                    compact={compact}
                    results={filteredOptions}
                    onSelectItem={onSelectItem}
                    showResultsTitles={!!filteredStaticOptions.length}
                    view={view}
                    selectedTab={selectedTab}
                />
            </div>
        </React.Fragment>
    );
};

export default TemplateDialogTemplates;
