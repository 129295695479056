import React from 'react';
import '../styles/group.scss';

export default class ToolbarGroup extends React.Component {
    render() {

        return (
            <div className="toolbar__group">
                {this.props.children}
            </div>
        );
    }
}
