import React from 'react';
import classNames from 'classnames';
import AlertBox from 'components/ui-components/AlertBox';
import Translation from 'components/data/Translation';

import '../styles/alert-box.scss';

/**
 * Show an alert box with the chosen AB test
 * @param {*} param0
 * @returns
 */
const VisualStackEditorAlertBox = ({ abTesting, abTestGroupsShown, className }) => {
    if (Array.isArray(abTesting) && Array.isArray(abTestGroupsShown) && abTestGroupsShown.length === 1) {
        return (
            <AlertBox type="info" hideClose className={classNames('visual-stack-editor-alert-box', className)}>
                {Translation.get('labels.preview', 'common')}{' '}
                <strong>{Translation.get('visualStackEditor.abtesting.test', 'editor', { test: abTestGroupsShown[0].toUpperCase() })}</strong>
            </AlertBox>
        );
    }

    return null;
};

export default VisualStackEditorAlertBox;
