import React from 'react';
import '../styles/main.scss';

/**
 * The Toolbar
 * This allows you to integrate several toolbar groups
 */
class Toolbar extends React.Component {

    render() {
        const { inline = false } = this.props;
        return (
            <div className={'toolbar ' + (inline ? 'toolbar--inline' : '')}>
                {this.props.children}
            </div>

        );
    }

}

export default (Toolbar);
