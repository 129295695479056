import React from 'react';
import Columns from 'components/ui-base/Columns';
import PreviewCard from 'components/ui-components/PreviewCard';
import PreviewListItem from 'components/ui-components/PreviewListItem';
import { TemplateDataResponse } from 'components/template-designer/services/template-designer.responses';
import Translation from 'components/data/Translation';
import TemplateDialogCompactBlock from './template-dialog-compact-block';
import '../styles/template-dialog-template-results.scss';

interface Props {
    results: any[];
    onSelectItem: (item: TemplateDataResponse, libraryTemplate: boolean) => void;
    showResultsTitles?: boolean;
    title?: string;
    view: 'grid' | 'list';
    compact?: boolean;
    selectedTab: string;
}

const TemplateDialogTemplateResults = ({
    results,
    onSelectItem,
    showResultsTitles,
    title = Translation.get('templatesDialog.templates.title', 'template-designer'),
    view,
    compact,
    selectedTab
}: Props) => {
    if (!results || !results.length) return null;

    return (
        <div className="template-dialog-template-results">
            {showResultsTitles && (
                <h4 className="template-dialog-template-results__title">
                    {title} ({results.length})
                </h4>
            )}
            {view === 'grid' && !compact && (
                <Columns count={3}>
                    {results.map((item, index) => (
                        <PreviewCard
                            className="template-dialog-template-results__preview-card"
                            key={index}
                            image={item.image}
                            title={item.title}
                            templateType={item.type}
                            description={item.description}
                            onSelectItem={() => onSelectItem(item, selectedTab === 'cape-library')}
                        />
                    ))}
                </Columns>
            )}
            {view === 'grid' && compact && (
                <Columns count={3}>
                    {results.map((item) => (
                        <TemplateDialogCompactBlock
                            key={item.id}
                            title={item.title}
                            icon={item.image}
                            description={item.description}
                            onSelectItem={() => onSelectItem(item, selectedTab === 'cape-library')}
                        />
                    ))}
                </Columns>
            )}
            {view === 'list' && (
                <div className="template-dialog-template-results__preview-list">
                    {results.map((item) => (
                        <PreviewListItem
                            key={item.id}
                            image={item.image}
                            title={item.title}
                            icon={item.image}
                            description={item.description}
                            onSelectItem={() => onSelectItem(item, selectedTab === 'cape-library')}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default TemplateDialogTemplateResults;
