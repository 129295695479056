import React from 'react';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Chip from 'components/ui-components-v2/Chip';
import EditorData from 'components/editor-data/EditorData';
import Translation from 'components/data/Translation';
import VisualStackEditorHelpers from 'components/data/VisualStackEditorHelpers';

import '../styles/summary-bar.scss';
import Templates from 'components/data/Templates';

/**
 * Setup the sidebar tabs
 */
const tabs = [
    {
        title: Translation.get('labels.design', 'common'),
        active: true,
        onClick: () => {}
    }
];

/**
 * Display the VisualStackEditor summary bar.
 * @param {*} param0
 * @returns
 */
const VisualEditorEditorSummaryBar = ({ value, dataModel, canEditAB, abTesting, abTestGroupsShown, onSetActive, templateType }) => {
    return (
        <div className="visual-stack-editor-summary-bar">
            <div className="visual-stack-editor-summary-bar__header">
                <div className="visual-stack-editor-summary-bar__tabbar">
                    {tabs.map((tab, index) => (
                        <div
                            key={`tab-${index}`}
                            className={classNames('visual-stack-editor-summary-bar__tabbar__tab', {
                                'visual-stack-editor-summary-bar__tabbar__tab--active': tab.active
                            })}
                            onClick={tab.onClick}>
                            {tab.title}
                        </div>
                    ))}
                </div>
            </div>
            {value &&
                value.map((block) => {
                    const template = Templates.get(templateType, block.identifier);
                    const content = EditorData.getValueFromModel(`${dataModel}.${block.uuid}`);
                    const hidden = VisualStackEditorHelpers.getHidden(content.abTestGroups, abTesting, abTestGroupsShown, false);
                    return hidden ? null : (
                        <div key={`summary-item-${block.uuid}`} className="visual-stack-editor-summary-bar__item" onClick={() => onSetActive(block.uuid, true)}>
                            <div className="visual-stack-editor-summary-bar__item__title">{template.title}</div>
                            <div className="visual-stack-editor-summary-bar__item__actions">
                                {abTesting &&
                                    abTesting.map((test) => (
                                        <Tooltip key={`test-${test}`} title={VisualStackEditorHelpers.getAbTooltip(content.abTestGroups, test)}>
                                            <>
                                                <Chip
                                                    size="small"
                                                    className="visual-stack-editor-summary-bar__item__chip"
                                                    label={`Test ${test.toUpperCase()}`}
                                                    clickable={canEditAB}
                                                    disabled={!canEditAB}
                                                    color={VisualStackEditorHelpers.getAbColor(content.abTestGroups, test)}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        VisualStackEditorHelpers.handleAbTesting(content.abTestGroups, test, block.uuid, dataModel);
                                                    }}
                                                />
                                            </>
                                        </Tooltip>
                                    ))}
                                <IconButton size="small" className="visual-stack-editor-summary-bar__item__btn">
                                    <Icon>chevron_right</Icon>
                                </IconButton>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default VisualEditorEditorSummaryBar;
