import React, { useRef, useState, useEffect } from 'react';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import CreativeManagementHelpers from 'components/data/CreativeManagementHelpers';
import InfiniteScroll from 'components/ui-components/InfiniteScroll';
import PreviewCard from 'components/ui-components/PreviewCard';
import Columns from 'components/ui-base/Columns';
import ComponentStore from 'components/data/ComponentStore';
import { GenericFilterChips } from 'components/ui-base/GenericFilter';
import PreviewListItem from 'components/ui-components/PreviewListItem';
import Setup from 'components/data/Setup';
import '../styles/template-dialog-creative-results.scss';

/**
 * If there are options selected for the templateIdentifiers, markets or brands that aren't returned with the relevant filters by the API,
 * we want to add them for display purposes in the filter chips.
 * @param {object} filters
 * @param {array} filterSetup
 * @param {object} templateLookup
 * @returns filterSetup with missing options for templateIdentifiers added.
 */
const getEnrichedFilterSetup = (filters, filterSetup, templateLookup) => {
    if (filters?.templateIdentifiers || filters?.brands || filters?.markets || filters?.departments) {
        const enrichedFilterSetup = filterSetup.map((f) => {
            if (!['templateIdentifiers', 'brands', 'markets', 'departments'].includes(f.name)) return f;
            if (!filters[f.name]) return f;
            const options = f.options;
            filters[f.name].forEach((t) => {
                if (!f.options.find((o) => o.key === t)) {
                    let value;
                    if (f.name === 'templateIdentifiers') {
                        value = templateLookup[t] || t;
                    } else if (Setup.get(f.name)) {
                        value = Setup.get(f.name)[t] || t;
                    } else {
                        value = t;
                    }
                    options.push({
                        key: t,
                        value,
                        count: 0
                    });
                }
            });
            return { ...f, options };
        });
        return enrichedFilterSetup;
    }
    return filterSetup;
};

const TemplateDialogCreativeResults = ({ results, hasMore, templateLookup, filters, filterSetup, templateTypes, onSelectItem, view }) => {
    const [enrichedFilterSetup, setEnrichedFilterSetup] = useState(getEnrichedFilterSetup(filters, filterSetup, templateLookup));
    if (!results || !results.length) return null;

    const scrollParentRef = useRef();

    useEffect(() => {
        setEnrichedFilterSetup(getEnrichedFilterSetup(filters, filterSetup, templateLookup));
    }, [filters, filterSetup, templateLookup]);

    const loadMore = () => {
        CreativeManagementHelpers.loadCreatives();
    };

    return (
        <div className="template-dialog-creative-results" ref={scrollParentRef}>
            <GenericFilterChips
                filterSetup={enrichedFilterSetup}
                filters={filters}
                onChange={(key, value) => CreativeManagementHelpers.changeFilter(key, value, filters)}
                onClearAll={() => ComponentStore.setModel('CreativeManagement', 'filters', { templateTypes })}
                className="template-dialog-creative-results__generic-filter-chips"
            />
            <InfiniteScroll
                loadMore={loadMore}
                hasMore={hasMore}
                initialLoad={false}
                getScrollParent={() => scrollParentRef.current}
                loader={<CircularProgress key={0} />}
                useWindow={false}>
                {view === 'grid' && (
                    <Columns count={3}>
                        {results.map((item) => (
                            <PreviewCard
                                className="template-dialog-creative-results__preview-card"
                                key={item.referenceId}
                                image={CreativeManagementHelpers.getPreviewImage(item)}
                                title={item.creativeData.title}
                                icon={item.icon}
                                description={
                                    templateLookup && templateLookup[item.templateType] && templateLookup[item.templateType].title
                                        ? templateLookup[item.templateType].title
                                        : 'Creative'
                                }
                                onSelectItem={() => onSelectItem(item.creativeData, true)}
                            />
                        ))}
                    </Columns>
                )}
                {view === 'list' && (
                    <div className="template-dialog-creative-results__preview-list">
                        {results.map((item) => (
                            <PreviewListItem
                                className="template-dialog-creative-results__preview-list-item"
                                key={item.referenceId}
                                image={CreativeManagementHelpers.getPreviewImage(item)}
                                title={item.creativeData.title}
                                icon={item.icon}
                                description={
                                    templateLookup && templateLookup[item.templateType] && templateLookup[item.templateType].title
                                        ? templateLookup[item.templateType].title
                                        : 'Creative'
                                }
                                onSelectItem={() => onSelectItem(item.creativeData, true)}
                            />
                        ))}
                    </div>
                )}
            </InfiniteScroll>
        </div>
    );
};

export default TemplateDialogCreativeResults;
