import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import FullScreenOverlay, {
    FullScreenOverlaySidebar,
    FullScreenOverlaySidebarTitle,
    FullScreenOverlaySidebarContainer,
    FullScreenOverlayWorkingArea,
    FullScreenOverlayCampaignActions
} from 'components/ui-base/FullScreenOverlay';
import EditorData from 'components/editor-data/EditorData';
import Translation from 'components/data/Translation';
import VisualStackEditorTopbar from './topbar';
import VisualStackEditorAlertBox from './alert-box';
import VisualStackEditorSummaryBar from './summary-bar';
import VisualStackEditorAbTestSettings from './ab-test-settings';
import VisualStackEditorEditInterface from './edit-interface';

import '../styles/main.scss';

/**
 * Display the VisualStackEditor. Used for cases wherin template blocks are stacked and ordered.
 * @param {*} param0
 * @returns
 */
const VisualStackEditor = ({
    value,
    dataModel,
    editingItem,
    subsets,
    language,
    title = Translation.get('actions.edit', 'common'),
    children,
    abTesting,
    abTestGroupsShown,
    originSelector,
    scrollPosition,
    canEdit,
    canEditAB,
    onClose,
    onSetAbTestGroupsShown,
    onSetActive,
    onCloseEdit,
    templateType
}) => {
    const scrollRef = useRef();
    useEffect(() => {
        scrollRef.current.scrollTo(0, scrollPosition);
    }, [scrollPosition]);

    /**
     * Store the save date in the data model.
     */
    const handleSave = () => {
        EditorData.setModel(dataModel + '.lastUpdate', moment().format());
        EditorData.setModel(dataModel + '.lastUpdatePrintable', moment().format('MMMM Do YYYY, HH:mm'));
    };

    return (
        <FullScreenOverlay title={title} rightButtons={<FullScreenOverlayCampaignActions onSave={handleSave} />} onClose={onClose}>
            <FullScreenOverlayWorkingArea showSidebar>
                <VisualStackEditorTopbar
                    className="visual-stack-editor__topbar"
                    abTesting={abTesting}
                    abTestGroupsShown={abTestGroupsShown}
                    value={value}
                    originSelector={originSelector}
                    onSetAbTestGroupsShown={(value) => onSetAbTestGroupsShown(value)}
                />
                <div className="visual-stack-editor__scroll" ref={scrollRef}>
                    <VisualStackEditorAlertBox abTesting={abTesting} abTestGroupsShown={abTestGroupsShown} />
                    {children && children}
                </div>
            </FullScreenOverlayWorkingArea>
            <FullScreenOverlaySidebar show={editingItem ? false : true}>
                <VisualStackEditorSummaryBar
                    value={value}
                    dataModel={dataModel}
                    activeBlock={editingItem && editingItem.uuid}
                    canEditAB={canEditAB}
                    abTesting={abTesting}
                    abTestGroupsShown={abTestGroupsShown}
                    onSetActive={onSetActive}
                    templateType={templateType}
                />
            </FullScreenOverlaySidebar>
            <FullScreenOverlaySidebar show={editingItem ? true : false}>
                <FullScreenOverlaySidebarTitle onClose={onCloseEdit} title={editingItem && editingItem.title} />
                {canEdit ? (
                    <FullScreenOverlaySidebarContainer>
                        {abTesting && canEditAB && (
                            <VisualStackEditorAbTestSettings abTesting={abTesting} dataModel={dataModel} activeBlock={editingItem && editingItem.uuid} />
                        )}
                        <VisualStackEditorEditInterface editingItem={editingItem} subsets={subsets} dataModel={dataModel} language={language} />
                    </FullScreenOverlaySidebarContainer>
                ) : (
                    <FullScreenOverlaySidebarContainer>{Translation.get('visualStackEditor.noEditingPermissions', 'editor')}</FullScreenOverlaySidebarContainer>
                )}
            </FullScreenOverlaySidebar>
        </FullScreenOverlay>
    );
};

export default VisualStackEditor;
