import React from 'react';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Chip from 'components/ui-components-v2/Chip';
import EditorData from 'components/editor-data/EditorData';
import VisualStackEditorHelpers from 'components/data/VisualStackEditorHelpers';
import Translation from 'components/data/Translation';

import '../styles/ab-test-settings.scss';

/**
 * Display the AB testing settings
 * @param {*} param0
 * @returns
 */
const VisualStackEditorAbTestSettings = ({ abTesting, dataModel, activeBlock }) => {
    const content = EditorData.getValueFromModel(`${dataModel}.${activeBlock}`);

    return (
        <div className="visual-stack-editor-ab-test-settings">
            <div className="visual-stack-editor-ab-test-settings__label">{Translation.get('visualStackEditor.abtesting.testing', 'editor')}</div>
            <div>
                {abTesting &&
                    abTesting.map((test) => (
                        <Tooltip key={`test-${test}`} title={VisualStackEditorHelpers.getAbTooltip(content.abTestGroups, test)}>
                            <span>
                                <Chip
                                    className="visual-stack-editor-ab-test-settings__chip"
                                    label={Translation.get('visualStackEditor.abtesting.test', 'editor', { test: test.toUpperCase() })}
                                    clickable
                                    color={VisualStackEditorHelpers.getAbColor(content.abTestGroups, test)}
                                    onClick={() => VisualStackEditorHelpers.handleAbTesting(content.abTestGroups, test, activeBlock, dataModel)}
                                />
                            </span>
                        </Tooltip>
                    ))}
            </div>
        </div>
    );
};

export default VisualStackEditorAbTestSettings;
