import React from 'react';
import classNames from 'classnames';
import Translation from 'components/data/Translation';
import '../styles/ab-test-tabbar.scss';

/**
 * Display the AB teting tabbar.
 * @param {*} param0
 * @returns
 */
const VisualStackEditorAbTestTabbar = ({ abTesting, abTestGroupsShown, onSetAbTestGroupsShown, className }) => {
    return (
        <div className={classNames(className, 'visual-stack-editor-ab-test-tabbar')}>
            <div
                className={classNames('visual-stack-editor-ab-test-tabbar__tab', {
                    'visual-stack-editor-ab-test-tabbar__tab--active': abTesting && abTestGroupsShown.length === abTesting.length
                })}
                onClick={() => onSetAbTestGroupsShown(abTesting)}>
                {Translation.get('visualStackEditor.abtesting.all', 'editor')}
            </div>
            {abTesting.map((x) => (
                <div
                    key={`test-${x}`}
                    className={classNames('visual-stack-editor-ab-test-tabbar__tab', {
                        'visual-stack-editor-ab-test-tabbar__tab--active': abTesting && abTestGroupsShown.length === 1 && abTestGroupsShown[0] === x
                    })}
                    onClick={() => onSetAbTestGroupsShown([x])}>
                    {Translation.get('visualStackEditor.abtesting.test', 'editor', { test: x.toUpperCase() })}
                </div>
            ))}
        </div>
    );
};

export default VisualStackEditorAbTestTabbar;
